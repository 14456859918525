import React from 'react'

import * as firebase from 'firebase'

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

import Assignment from "@material-ui/icons/Assignment"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator'

import Image from 'react-bootstrap/Image'

import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import IconButton from '@material-ui/core/IconButton'
import Close from "@material-ui/icons/Close"
import Problem from "@material-ui/icons/ReportProblem"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import ArrowDropUp from '@material-ui/icons/ArrowDropUp'

import MenuItem from "@material-ui/core/MenuItem"

import Collapse from '@material-ui/core/Collapse'

import TextField from '@material-ui/core/TextField'
import CardContent from '@material-ui/core/CardContent'

import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"

import CircularProgress from '@material-ui/core/CircularProgress'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import BivResultCount from 'components/BivResultCount/BivResultCount.jsx'
import BivButtonFind from 'components/BivButtonFind/BivButtonFind.jsx'

import BivPagination from 'components/BivPagination/BivPagination.jsx'

import { ExportCSV } from 'components/ExportCSV/ExportCSV.jsx'

import utilities from 'utilities/utilities.jsx'

import utilTable from 'utilities/utilTable.jsx'

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import ClearIcon from '@material-ui/icons/Clear'

import moment from "moment"
import GridEmptySpace from 'components/GridEmptySpace/GridEmptySpace'

moment.locale("es")
const languages = require('../../assets/translate/translate_button.json')
let lang = localStorage.lang ? localStorage.lang : 'es'

class BivEnroll extends React.Component {

  constructor(props) {
    super(props);
    this.rol=global.rol.authorities.permission

    this.state = {
      loading: true,
      showFilters: false,
      showEnrollDetail: false,
      showEnrollDismiss: false,

      searchStartDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
      searchToDate: moment().format('YYYY-MM-DD'),
      searchResultOrigin: 'Todos',
      searchResultState: 'Todos',
      filterText: '',

      enrollsTableComplete: [],
      enrollsTable: [],
      enrollsTableForExport: [],
      enrollSelected: [],

      textDni: '',
      textBirDate: '',
      textSex: true,
      textName: '',
      textLastName: '',
      textDescription: '',

      frenteDni: true,

      dismissDialog: false,
      dismissAcceptDialog: false,

      photoDialog: false,
      photoSelected: 0,

      _userVerify: null,


      _dismissDialog: false,
      _dismissAcceptDialog: false,

      _buttonAccept: false,
      _buttonDismiss: false,
      detalleUsuario: false,
      _completeElCampo: {}
    }
  }

  componentDidMount() {
    this.textDescription = ""
    this.newLoadData()

    //Update mr in firebase
    //this.fixDataInFirebase()

  }

  async fixDataInFirebase() {

    await firebase.database().ref('requests').once('value', async (x) => {
      if (x.val()) {
        let _padronInfo = x.val()
        let _keys = Object.keys(_padronInfo)

        for (let i = 0; i < _keys.length; i++) {
          let key = _keys[i]
          //let _info = _padronInfo[key]

          await firebase.database().ref('padronInformation/' + key).once('value', async (c) => {
            if (c.val()) {
              await firebase.database().ref('padronInformation/' + key).update({ mr: true })
            } else {
            }
          })

        }
      }
    })

  }

  obtenerTipo(data) {
    let _mr = data.mr ? data.mr : false
    let _r = data.r ? true : false



    if (data.ty + '' === "1") {
      return "Auto"
    }
    else if (data.ty + '' === "2") {
      return "Manual"
    }
    else if (data.ty + '' === "3") {
      return "Manual"
    }
    else {
      return "Incompleto"
    }


  }

  obtenerEstado(_data, _type) {
    const _r = _data.r + ""
    const _ty = _data.ty + ""
    if (_ty === "1" && _r === "1") {
      return "Aprobada"
    }
    switch (_r) {
      case "0":
        return "En espera"
      case "1":
        return "Aprobada"

      case "2":
        return "Rechazada"
      default:
        return ""
    }
    return

  }

  async newLoadData() {

    let _requests = {}
    let _padrons = {}
    let _data = []

    let _dateFrom = moment(this.state.searchStartDate).startOf('day').valueOf()
    _dateFrom--
    let _dateTo = moment(this.state.searchToDate).endOf('day').valueOf()
    _dateTo++

    //await firebase.database().ref('requests').once('value', (x) => {
    //if (x.val()) {
    //_requests = x.val()
    //}
    //})

    let call = new Promise((resolve, reject) => {
      let myHeaders = new Headers();
      let _token = localStorage.getItem('fb_jwt')
      myHeaders.append("Authorization", "Bearer " + _token);


      let formdata = new FormData();
      formdata.append("start", _dateFrom);
      formdata.append("end", _dateTo);

      let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };
      fetch(global.Direction_Back_panel + "/panel/getEnrolls", requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            if (result.res.request) {
              Object.entries(result.res.request).forEach(function (child) {
                _requests[child[0]] = child[1]
              });
            }
            if (result.res.padronInforrmation) {
              Object.entries(result.res.padronInforrmation).forEach(function (child) {
                _padrons[child[0]] = child[1]
              });
            }
            resolve()
          }
          reject({case:1})
        })
        .catch(error => {
          
          reject({case:2})
        });
    })



    // await firebase.database().ref('requests')
    //   .orderByChild("t")
    //   .startAt(_dateFrom)
    //   .endAt(_dateTo)
    //   .once('value', function (snapshot) {
    //     snapshot.forEach(function (child) {
    //       _requests[child.key] = child.val()
    //     });
    //   })

    // //await firebase.database().ref('padronInformation').once('value', (x) => {
    // //if (x.val()) { _padrons = x.val() }
    // //})

    // await firebase.database().ref('padronInformation')
    //   .orderByChild("ad")
    //   .startAt(_dateFrom)
    //   .endAt(_dateTo)
    //   .once('value', function (snapshot) {
    //     snapshot.forEach(function (child) {
    //       _padrons[child.key] = child.val()
    //     });
    //   })
    call.then(() => {
      let _padronsKeys = Object.keys(_padrons)

      //Automáticos Aprobados: que son los que contienen los campos "d" , "i0" y "i1", que NO contiene el campo "mr: true" ni el campo "r:0"
      //Automaticos Rechazados: no existen
      //Automaticos en espera: no existen
      //Manuales Aprobados: son los que contienen los campos "mr:true" y "sr:1" 
      //Manuales Rechazados: son los que contienen los campos "mr:true" y "sr:2"
      //Manuales en Espera : son los que contienen "r:0"

      //Cuando acepto un empadronamiento manual cambio mr:true, r:1 y sr:1
      //Cuando rechazo un empadronamiento manual cambio mr:true, r:2 y sr:2

      //"Manuales En espera" y "Manueales rechazados" La falra de datos de  "padronInformation"  se rellena de  "request"

      for (let i = 0; i < _padronsKeys.length; i++) {
        let _key = _padronsKeys[i]
        let _padron = _padrons[_key]
        let _request = _requests[_key]
        let _type = this.obtenerTipo(_padron)
        let _state = this.obtenerEstado(_padron, _type)

        if (_type !== 'Incompleto' && _state.length > 2) {
          let _reg



          // if (_type === 'Auto') {

          //   _reg = this.fillComun(i, _padron, _request, _key, _state, _type)

          // } else {

          if (_state === "Aprobada") {
            _reg = this.fillComun(i, _padron, _request, _key, _state, _type)
          } else {
            _reg = this.fillWithRequest(i, _padron, _request, _key, _state, _type)
          }
          // }
          _data.push(_reg)
        }

      }

      this.setState({
        enrollsTableComplete: _data,
      }, this.applyNewFilter)

    }).catch((err) => {
      if(err.case==2){
        global.showNoPermission({timeout:true})
      }
      else{
        global.showNoPermission()
      }
      this.setState({
        enrollsTableComplete: _data,
      }, this.applyNewFilter)
    })

  }

  fillComun(_i, _data, _request, _key, _state, _type) {

    let _reg

    let _apellido = _data.d ? _data.d.s ? _data.d.s : '' : ''
    let _nombre = _data.d ? _data.d.n ? _data.d.n : '' : ''

    //let _date = Number(_data.t ? _data.t : _data.d ? _data.d.t ? _data.d.t : moment().format("x") : moment().format("x"))
    let _date = Number(_data.ct ? _data.ct : moment().format("x"))


    let _fn = _data ? _data.d ? _data.d.b ? moment(_data.d.b, 'DD/MM/YYYY').format('x') : moment().format('x') : moment().format('x') : moment().format('x')

    let _motivo = _request ? _request.d ? _request.d : '' : ''

    let _me = _data ? _data.me ? _data.me === "success" ? 1 : 0 : 0 : 0
    let _ve = (_data && _data.ve) ? _data.ve : null
    let _img0
    let _img1
    if ((_data.ty + '' === '2' || _data.ty + '' === '3') && (_data.r + "" === "0" || _data.r + "" === "2")) {

      _img0 = _request && _request.i ? _request.i : ''
      _img1 = _request && _request.i0 ? _request.i0 : ''
    }
    else {

      _img0 = _data.i0 && _data.i0.i ? _data.i0.i : ''
      _img1 = _data.i1 && _data.i1.i ? _data.i1.i : ''
    }
    let _firstTimeUser = Number(_data.ct ? _data.ct :_data.t)
    let _lastChangeUser = Number(_data.ad ? _data.ad : _firstTimeUser)
    let _lastChangeUni = Number(_data.la ? _data.la : _lastChangeUser)
    _reg = {
      _lastChangeUser: moment(_lastChangeUser).format("DD/MM/YYYY"),
      _firstTimeUser: moment(_firstTimeUser).format("DD/MM/YYYY"),
      _lastChangeUni: moment(_lastChangeUni).format("DD/MM/YYYY"),
      key: _i,
      fbkey: _key,
      d: _data.d,
      userid: _key,
      user: this.nameCleaner(_key),
      name: (_apellido === '') ? (_nombre === '') ? '' : _nombre : (_nombre === '') ? _apellido : _nombre+ ' '+ _apellido,
      apellido: _apellido,
      nombre: _nombre,
      sexo: _data.d ? _data.d.g ? (_data.d.g === 'F') ? true : false : false : false,
      fechanacimiento: Number(_fn),
      dni: _data.d ? _data.d.dni ? _data.d.dni : '' : '',
      date: moment(_date).format("DD/MM/YYYY"),
      datetimestamp: _date,
      res: _state,
      det: _data.det,
      ty: _data.ty,
      userimg: _img0,
      dniimg1: _img1,
      dniimg2: _data.i2 ? _data.i2.i ? _data.i2.i : '' : '',
      tipo: _type,
      r: _data.r ? Number(_data.r) : 0,
      motivo: _motivo,
      me: _me,
      ve: _ve,

    }

    return _reg

  }

  reset = () => {
    // Object.keys(this.state).map((key, index) => { 
    this.setState({ searchFilter: "", searchResultState: "Todos", searchResultOrigin: "Todos", filterText: "" }, this.findfunc());
    // });
  }

  findfunc = () => {
    this.setState({
      loading: true
    }, this.newLoadData)
  }

  getDate(data, request) {

    let _dateDTExist = data ? data.t ? true : false : false
    if (_dateDTExist === true) {
      return Number(moment(Number(data.t)).format("x"))
    }

    let _dateDDTExist = data ? data.d ? data.d.t ? true : false : false : false
    if (_dateDDTExist === true) {
      return Number(moment(Number(data.d.t)).format("x"))
    }

    let _dateRFExist = request ? request.f ? true : false : false
    if (_dateRFExist === true) {
      return Number(moment(Number(request.f)).format("x"))
    }

    let _dateRFTExist = request ? request.t ? true : false : false
    if (_dateRFTExist === true) {
      return Number(moment(Number(request.t)).format("x"))
    }

    return Number(moment('01/09/2020', 'DD/MM/YYYY').format("x"))

  }

  fillWithRequest(_i, _data, _request, _key, _state, _type) {

    let _reg

    let _apellido = _data.d && _data.d.s ? _data.d.s : _request ? _request.a ? _request.a : '' : ''
    let _nombre = _data.d && _data.d.n ? _data.d.n : _request ? _request.n ? _request.n : '' : ''

    let _motivo = _request ? _request.d ? _request.d : '-' : '-'

    //let _date = this.getDate(_data, _request)
    let _date = Number(_data.ct ? _data.ct : moment().format("x"))

    let _fn = _data ? _data.d ? _data.d.b ? moment(_data.d.b, 'DD/MM/YYYY').format('x') : moment().format('x') : moment().format('x') : moment().format('x')

    let _me = _data ? _data.me ? _data.me === "sucess" ? 1 : 0 : 0 : 0
    let _ve = (_data && _data.ve) ? _data.ve : null
    let _img0
    let _img1

    if ((_data.ty + '' === '2' || _data.ty + '' === '3') && _data.r + "" === "0") {
      _img0 = _request && _request.i ? _request.i : ''
      _img1 = _request && _request.i0 ? _request.i0 : ''
    }
    else {
      _img0 = _data.i0 && _data.i0.i ? _data.i0.i : ''
      _img1 = _data.i1 && _data.i1.i ? _data.i1.i : ''
    }
    let _firstTimeUser = Number(_data.ct ? _data.ct : moment().format("x"))
    let _lastChangeUser = Number(_data.ad ? _data.ad : _data.ct)
    let _lastChangeUni = Number(_data.la ? _data.la : 0)
    _reg = {
      _lastChangeUser: moment(_lastChangeUser).format("DD/MM/YYYY"),
      _firstTimeUser: moment(_firstTimeUser).format("DD/MM/YYYY"),
      _lastChangeUni: _lastChangeUni > 0 ? moment(_lastChangeUni).format("DD/MM/YYYY") : null,
      key: _i,
      fbkey: _key,
      d: _data.d,
      userid: _request ? _request.k ? _request.k : _key : _key,
      user: this.nameCleaner(_request ? _request.k ? _request.k : _key : _key),
      name: (_apellido === '') ? (_nombre === '') ? '' : _nombre : (_nombre === '') ? _apellido : _apellido + ', ' + _nombre,
      apellido: _apellido,
      nombre: _nombre,
      sexo: _data.d ? _data.d.g ? (_data.d.g === 'F') ? true : false : _request ? _request.s ? _request.s : false : false : _request ? _request.s ? _request.s : false : false,
      fechanacimiento: Number(_fn),
      dni: _data.d ? _data.d.dni ? _data.d.dni : _request ? _request.dn ? _request.dn : '' : '' : _request ? _request.dn ? _request.dn : '' : '',
      date: moment(_date).format("DD/MM/YYYY"),
      datetimestamp: Number(_date),
      res: _state,
      det: _data.det,

      //  det: _request ? _request.d ? _request.d : '-' : '-',
      userimg: _img0,
      dniimg1: _img1,
      dniimg2: _data.i2 ? _data.i2.i ? _data.i2.i : _request ? _request.i1 ? _request.i1 : '' : '' : _request ? _request.i1 ? _request.i1 : '' : '',
      tipo: _type,
      r: _data.r ? Number(_data.r) : 0,
      ty: _data.ty,
      motivo: _motivo,
      me: _me,
      ve: _ve,
    }

    return _reg

  }

  applyNewFilter() {


    let _enrollsTable = []
    let _enrollsTableForExport = []

    for (let i = 0; i < this.state.enrollsTableComplete.length; i++) {

      if (this.requirementFilter(this.state.enrollsTableComplete[i])) {

        let elem = this.state.enrollsTableComplete[i]

        if (this.state.filterText === '') {

          _enrollsTable.push(elem)
          _enrollsTableForExport.push(this.toExport(elem))

        } else {

          if ((elem.apellido ? elem.apellido : '').toString().toUpperCase().includes(this.state.filterText.toUpperCase())) {
            _enrollsTable.push(elem)
            _enrollsTableForExport.push(this.toExport(elem))
            continue
          }

          if ((elem.nombre ? elem.nombre : '').toString().toUpperCase().includes(this.state.filterText.toUpperCase())) {
            _enrollsTable.push(elem)
            _enrollsTableForExport.push(this.toExport(elem))
            continue
          }

          if ((this.state.enrollsTableComplete[i].dni + '').includes(this.state.filterText)) {
            _enrollsTable.push(this.state.enrollsTableComplete[i])
            _enrollsTableForExport.push(this.toExport(this.state.enrollsTableComplete[i]))
            continue
          }

          if ((elem.userid ? elem.userid : '').toString().toUpperCase().includes(this.state.filterText.toUpperCase())) {
            _enrollsTable.push(elem)
            _enrollsTableForExport.push(this.toExport(elem))
            continue
          }

          if ((elem.det ? elem.det : '').toString().toUpperCase().includes(this.state.filterText.toUpperCase())) {
            _enrollsTable.push(elem)
            _enrollsTableForExport.push(this.toExport(elem))
            continue
          }

          if ((elem.res ? elem.res : '').toString().toUpperCase().includes(this.state.filterText.toUpperCase())) {
            _enrollsTable.push(elem)
            _enrollsTableForExport.push(this.toExport(elem))
            continue
          }
        }

      }

    }

    this.setState({
      enrollsTable: _enrollsTable,
      enrollsTableForExport: _enrollsTableForExport,
      loading: false,
    })
  }
  // aca la estas definiendo p
  //
  setStatus(a, b) {
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    let _data_ = JSON.stringify({ "user": a, "st": b })
    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: _data_,
      redirect: 'follow'
    };

    // fetch(Direction_Back_panel + "/saveStatus", requestOptions)
    //   .then(response => response.text())
    //   .then(result => { })
    //   .catch(error => console.log('error', error))
  }

  toExport(data) {
    let ve
    if (data.ve) {
      if (+data.ve === 1) {
        ve = 'Aceptado'
      } else if (+data.ve === 2) {
        ve = 'Rechazado'
      }
    } else {
      ve = 'Sin verificar'
    }
    let _newData = {
      Nombre: data.name,
      Usuario: data.user,
      Dni: data.dni,
      //FechaDeNacimiento: moment(data.fechanacimiento).format('DD/MM/YYYY'),
      'Primer solicitud': data._firstTimeUser,
      'Última solicitud': data._lastChangeUser,
      'Última auditoría': data._lastChangeUni,
      // ['Última auditoría']: data.date,
      Verificado: ve,
      //ExamenIniciarFinalizado: (data.me === 1) ? 'SI' : 'NO',
      'Tipo de registro': (data.tipo === 'Manual') ? 'Manual' : 'Automático',
      'Estado de registro': data.res,
    }

    return _newData

  }

  requirementFilter(field) {

    //let dateFrom = moment(this.state.searchStartDate).startOf('day').valueOf()
    //let dateTo = moment(this.state.searchToDate).startOf('day').add(1, 'days').valueOf()
    //dateTo--

    //if (field.datetimestamp < dateFrom) {
    //return false
    //}

    //if (field.datetimestamp > dateTo) {
    //return false
    //}

    let _pass = false

    switch (this.state.searchResultOrigin) {
      case "Todos":
        _pass = true
        break
      case "Registros manuales":
        if (field.tipo === 'Manual') {
          _pass = true
        } else {
          _pass = false
        }
        break
      case "Registros automáticos":
        if (field.tipo === 'Auto') {
          _pass = true
        } else {
          _pass = false
        }
        break
      default:
        _pass = false
        break
    }

    if (_pass === true) {

      switch (this.state.searchResultState) {
        case "Todos":
          return true
        case "Aprobados":
          if (field.res === 'Aprobada') {
            return true
          } else {
            return false
          }
        case "Rechazados":
          if (field.res === 'Rechazada') {
            return true
          } else {
            return false
          }
        case "En espera":
          if (field.res === 'En espera') {
            return true
          } else {
            return false
          }

        default:
          return false
      }
    } else {
      return false
    }
  }

  nameCleaner(name) {
    if (name.length > 2) {
      if (name.substring(0, 2).toUpperCase() === "ID") {
        let _name = name.substring(2, name.length)
        return _name.toString().toUpperCase()
      }
    } else {
      return name.toString().toUpperCase()
    }
  }

  checkData() {
    if (!this.state.enrollFinalized) {
      let _resp = false
      let _checkDNI = true
      let _checkBirDate = false
      let _checkName = false
      let _checkLastName = false
      let _userVerify = false
      let _descriptionDenied = false

      let _completeElCampo = {
        dni: false,
        birDate: false,
        name: false,
        lastname: false
      }

      //Dni check

      if (this.state.textDni.length > 2) { _checkDNI = true }

      if (!this.state.textDni || this.state.textDni.length === 0 || this.state.textDni < 800000) { _completeElCampo.dni = true }

      //Bir. check
      if (this.state.textBirDate) {

        if (moment(this.state.textBirDate).valueOf() < moment(Date.now()).valueOf()) {
          _checkBirDate = true
        }
      }

      if (!this.state.textBirDate || this.state.textBirDate.length === 0) { _completeElCampo.birDate = true }


      //Name check
      if (this.state.textName.length > 2) { _checkName = true }

      if (!this.state.textName || this.state.textName.length < 3) { _completeElCampo.name = true }


      //Last name check
      if (this.state.textLastName.length > 2) { _checkLastName = true }

      if (!this.state.textLastName || this.state.textLastName.length < 3) { _completeElCampo.lastname = true }


      //Detalle check
      if (+this.state._userVerify === 1) { _userVerify = true; _descriptionDenied = true }

      if (+this.state._userVerify === 2 && this.textDescription && this.textDescription.length > 5) { _userVerify = true; _descriptionDenied = true }

      //General check
      if ((_checkDNI && _checkBirDate && _checkName && _checkLastName && _userVerify && _descriptionDenied) || (+this.state._userVerify === 2 && _userVerify && _descriptionDenied)) {
        _resp = true
      }

      this.setState({ buttonAccept: _resp, _buttonAccept: _resp, "_completeElCampo": _completeElCampo }, () => {
        return _resp
      })

    }
    return false
  }

  checkDesciptionData() {
    let _res = false
    if (this.textDescription.length > 5) {
      _res = true
    }
    this.setState({ dismissAcceptDialog: _res, _dismissAcceptDialog: _res }, this.checkData)
  }

  verUs(x) {
    this.setState({ _userVerify: x }, this.checkData)

    return true
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.acceptDismissEnroll()
    }
  }

  _handleKeyPress(e) {

  }
  handleKeyDown(e) {
    if (e.keyCode === 27) {
      this.cancelDismissEnroll()
    }
    if (e.key === "Enter") {
      this.setState({ loading: true }, this.acceptEnroll)
      // this.acceptEnroll()
    }
  }

  handleOnChange(e) {
    this.textDescription = e.target.value
    this.checkDesciptionData()
    // this.setState({ textDescription: e.target.value }, this.checkDesciptionData)
  }

  acceptEnroll() {

    let _enroll = {}
    let time = Date.now()
    let actualUser = this.state.enrollSelected

    //Cuando acepto un empadronamiento manual cambio mr:true, r:1 y sr:1
    let _userDataGenerated = {
      "dni": this.state.textDni,
      "n": this.state.textName,
      "s": this.state.textLastName,
      "t": time,
    }
    let _userData = actualUser.d
    let changes = false
    if (!_userData) {
      changes = true
    }
    else {
      if (_userData.n !== _userDataGenerated.n || _userData.s !== _userDataGenerated.s || _userData.dni !== _userDataGenerated.dni) {
        changes = true
      }
    }

    //Cuando acepto un empadronamiento manual cambio mr:true, r:1 y sr:1
    if (changes) {
      _enroll['d'] = _userDataGenerated
    }
    let status
    _enroll["ve"] = this.state._userVerify
    if (+this.state._userVerify === 1) {
      _enroll["det"] = null
      _enroll["me"] = 'success'
      status = "1"
    }
    else if (+this.state._userVerify === 2) {
      _enroll["det"] = this.textDescription
      _enroll['mr'] = true
      status = "2"
    }
    if (((actualUser.ty + '' === "2" || actualUser.ty + '' === "3") && actualUser.r + '' === "0") && status + '' === "1") {
      _enroll['i0'] = {
        "i": actualUser.userimg,
        "t": Number(time),
      }

      _enroll['i1'] = {
        "i": actualUser.dniimg1,
        "t": Number(time),
      }
    }
    _enroll['r'] = status
    if(status==2){
      _enroll['pb']=false
    }
    _enroll['t'] = Number(time)
    //fecha de actualizacion 
    _enroll['ut'] = Number(time)
    //fecha de auditoria
    _enroll['la'] = Number(time)

    let myHeaders = new Headers();
    let _token = localStorage.getItem('fb_jwt')
    myHeaders.append("Authorization", "Bearer " + _token);


    let formdata = new FormData();
    formdata.append("id", actualUser.userid);
    formdata.append("data", JSON.stringify({
      r: 1,
      a: this.state.textLastName,
      n: this.state.textName,
      s: (this.state.textSex === true) ? true : false,
      f: Number(moment(this.state.textBirDate).format('x')),
      dn: this.state.textDni,
    }));
    let requestOptions = {
      headers: myHeaders,
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    fetch(global.Direction_Back_panel + "/panel/setRequest", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.message && result.message === "Unauthorized") {
          global.showNoPermission()
          this.setState({
            showEnrollDetail: false,
            dismissDialog: false,
            loading:false,
            photoDialog: false,
          })
        }
        else{

        
        let formdata = new FormData();
        formdata.append("id", actualUser.userid);
        formdata.append("data", JSON.stringify(_enroll));
        let requestOptions = {
          headers: myHeaders,
          method: 'POST',
          body: formdata,
          redirect: 'follow'
        };
        fetch(global.Direction_Back_panel + "/panel/setPadronInfo", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.message && result.message === "Unauthorized") {
              global.showNoPermission()
              this.setState({
                showEnrollDetail: false,
                dismissDialog: false,
                loading:false,
                photoDialog: false,
              })
            }
            else {


              firebase.database().ref('padronInformation/' + actualUser.userid).once('value').then((_user_) => {
                _user_ = _user_.val()
                let notif
                if ((_user_.ty + '' === '2' || _user_.ty + '' === '3') && status + '' === '1') {
                  notif = 1
                }
                if ((_user_.ty + '' === '2' || _user_.ty + '' === '3') && status + '' === '2') {
                  notif = 2
                }
                if ((_user_.ty + '' === '1') && status + '' === '2') {
                  notif = 3
                }
                if ((_user_.ty + '' === '1') && status + '' === '1') {
                  notif = 1
                }
                this.setStatus(_user_, notif)
              })
            }

            this.setState({
              showEnrollDetail: false,
              dismissDialog: false,
              photoDialog: false,
            }, this.newLoadData)
          })
          .catch(error => console.log('error', error));
      }

      })
      
      .catch(error => console.log('error', error));

    // firebase.database().ref('requests/' + actualUser.userid).update({
    //   //d: this.textDescription ? this.textDescription : "",
    //   //i: actualUser.userimg,
    //   //i0: actualUser.dniimg1,
    //   //i1: actualUser.dniimg2,
    //   //k: this.state.enrollSelected.userid,
    //   r: 1,
    //   //t: Number(this.state.enrollSelected.datetimestamp),
    //   a: this.state.textLastName,
    //   n: this.state.textName,
    //   s: (this.state.textSex === true) ? true : false,
    //   f: Number(moment(this.state.textBirDate).format('x')),
    //   dn: this.state.textDni,
    // }, () => {
    // firebase.database().ref('padronInformation/' + actualUser.userid).update(_enroll).then(() => {

    // })
    // })

  }

  acceptDismissEnroll() {
    const _enrollSelected = this.state.enrollSelected.userid
    firebase.database().ref('requests/' + _enrollSelected.userid).update({
      d: this.textDescription ? this.textDescription : "",
      i: _enrollSelected.userimg,
      i0: _enrollSelected.dniimg1,
      i1: _enrollSelected.dniimg2,
      k: _enrollSelected.userid,
      r: 2,
      //t: Number(this.state.enrollSelected.datetimestamp),
      a: this.state.textLastName,
      n: this.state.textName,
      s: (this.state.textSex === true) ? true : false,
      f: Number(moment(this.state.textBirDate).format('x')),
      dn: this.state.textDni,
    }, () => {

      //Cuando rechazo un empadronamiento manual cambio mr:true, r:2 y sr:2

      let _enroll = { r: "2", sr: "2", mr: true, det: this.textDescription }
      firebase.database().ref('padronInformation/' + _enrollSelected.userid).update(_enroll).then(() => {
        this.setState({
          showEnrollDetail: false,
          dismissDialog: false,
          photoDialog: false,
        }, this.newLoadData)
      })
    });

  }

  cancelarVerificacion() {
    this.setState({
      cancelButton: false,
    })
  }


  cancelDismissEnroll() {
    this.setState({
      _userVerify: null,
      _dismissDialog: false,
      dismissDialog: false,
      buttonDismiss: true,
      _buttonDismiss: true,
      textDescription: '',
      showEnrollDetail: false,
      photoDialog: false,
    }, this.checkData)
  }

  sortIcon(order, column) {
    if (!order) return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
    else if (order === 'asc') return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
    else if (order === 'desc') return (<span style={{ color: "gray" }}><ArrowDropUp /></span>)
    return null
  }


  render() {

    const formatWithIconSee = (cell, row) => {
      return (
        <Button color="violetBiv" onClick={() => {

          this.setState({ loadingItem: true })
          this.textDescription = row.det
          this.setState({
            enrollSelected: row,
            showEnrollDetail: true,
            textDni: row.dni,
            _userVerify: row.ve,
            //textDescription:row.det,
            textBirDate: moment(row.fechanacimiento, 'x').format('YYYY-MM-DD'),

            textSex: (row.sexo === true) ? true : false,
            textName: (row.nombre === '-') ? (row.res === 'En espera') ? '' : '-' : row.nombre,
            textLastName: (row.apellido === '-') ? (row.res === 'En espera') ? '' : '-' : row.apellido,

            frenteDni: true,
            //textDescription: '',
            buttonAccept: true,
            buttonDismiss: true,
            _buttonAccept: true,
            _buttonDismiss: true,

          }, this.checkData)
        }} round>{languages[lang].button_watch}</Button>
      )
    }

    const tableHeader = [
      {
        dataField: 'key',
        text: 'Key',
        hidden: true,
        editable: false,
      }, {
        dataField: 'userid',
        text: languages[lang].button_userID1,
        hidden: true,
        editable: false,
        sort: true,
      }, {
        dataField: 'user',
        text: languages[lang].button_user,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '98px' },
        sortCaret: this.sortIcon,
      }, {
        dataField: 'name',
        text: languages[lang].button_nameLastName,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '165px' },
        sortCaret: this.sortIcon,
      }, {
        dataField: 'dni',
        text: global.dni_name,
        editable: false,
        sort: true,
        headerStyle: { minWidth: '73px' },
        sortCaret: this.sortIcon,
      },
      //  {
      //   dataField: 'date',
      //   text: languages[lang].button_applicationDate,
      //   editable: false,
      //   sort: true,
      //   sortFunc: (a, b, order) => {
      //     if (order === 'asc') {
      //       //return Date.parse(moment(a, 'DD/MM/YYYY').format('MM/DD/YYYY')) - Date.parse(moment(b, 'DD/MM/YYYY').format('MM/DD/YYYY'))
      //       return Number(moment(a, 'DD/MM/YYYY').format('x')) - Number(moment(b, 'DD/MM/YYYY').format('x'))
      //     } else if (order === 'desc') {
      //       //return Date.parse(moment(b, 'DD/MM/YYYY').format('MM/DD/YYYY')) - Date.parse(moment(a, 'DD/MM/YYYY').format('MM/DD/YYYY'))
      //       return Number(moment(b, 'DD/MM/YYYY').format('x')) - Number(moment(a, 'DD/MM/YYYY').format('x'))
      //     }
      //   },
      //   headerStyle: { minWidth: '164px' },
      //   sortCaret: this.sortIcon,
      // },
      {
        dataField: '_lastChangeUser',
        text: languages[lang].button_applicationDate,
        editable: false,
        sort: true,
        sortFunc: (a, b, order) => {
          if (order === 'asc') {
            //return Date.parse(moment(a, 'DD/MM/YYYY').format('MM/DD/YYYY')) - Date.parse(moment(b, 'DD/MM/YYYY').format('MM/DD/YYYY'))
            return Number(moment(a, 'DD/MM/YYYY').format('x')) - Number(moment(b, 'DD/MM/YYYY').format('x'))
          } else if (order === 'desc') {
            //return Date.parse(moment(b, 'DD/MM/YYYY').format('MM/DD/YYYY')) - Date.parse(moment(a, 'DD/MM/YYYY').format('MM/DD/YYYY'))
            return Number(moment(b, 'DD/MM/YYYY').format('x')) - Number(moment(a, 'DD/MM/YYYY').format('x'))
          }
        },
        headerStyle: { minWidth: '164px' },
        sortCaret: this.sortIcon,
      }, {
        dataField: 'res',
        text: languages[lang].button_state,
        editable: false,
        sort: true,
        formatter: utilTable.formatTextEnrollState,
        headerStyle: { minWidth: '93px' },
        sortCaret: this.sortIcon,
      }, {
        dataField: 've',
        text: languages[lang].button_verified,
        editable: false,
        sort: true,
        style: utilTable.formatStyleIcon,
        formatter: utilTable.formatIconVerifiedUser,
        headerStyle: { minWidth: '141px' },
        sortCaret: this.sortIcon,
      },
      // }, {
      //   dataField: 'me',
      //   text: 'Examen Inicial',
      //   editable: false,
      //   sort: true,
      //   style: utilTable.formatStyleIcon,
      //   formatter: utilTable.formatIconInitialExamnSucess,
      //   headerStyle: { minWidth: '141px' },
      //   sortCaret: this.sortIcon,
      // }, {
      {
        dataField: 'key',
        text: languages[lang].button_action,
        formatter: formatWithIconSee,
        sort: true,
        headerStyle: { minWidth: '94px' },
        sortCaret: this.sortIcon,
      }];

    const typesOfOrigin = [
      {
        value: "Todos",
        label: languages[lang].button_all,
      },
      {
        value: "Registros manuales",
        label: languages[lang].button_manualRegistrations,
      },
      {
        value: "Registros automáticos",
        label: languages[lang].button_automaticRegistrations,
      },
    ];

    const typesOfState = [
      {
        value: "Todos",
        label: languages[lang].button_all,
      },
      {
        value: "Aprobados",
        label: languages[lang].button_approved,
      },
      {
        value: "Rechazados",
        label: languages[lang].button_rejected,
      },
      {
        value: "En espera",
        label: languages[lang].button_onHold,
      },
    ];

    return (

      <div style={{ display: 'flex', flexDirection: "column", overflow: 'visible' }}>

        {this.state.loading ?
          <div style={{ position: 'fixed', left: 0, top: 0, backgroundColor: '#ffffff', opacity: '0.7', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', zIndex: 9999 }}>
            <CircularProgress />
          </div>
          :
          <div />
        }

        <GridContainer style={{ paddingLeft: 15, paddingRight: 15 }} >

          <GridItem xs={12}>
            <Card>

              {/* Icono y Titulo */}
              <CardHeader color="lightBlueBiv" icon>
                <GridContainer>
                  <GridItem xs={10} sm={10} md={10} lg={10} xl={10}>
                    <CardIcon color="lightBlueKW">
                      <Assignment />
                    </CardIcon>
                    <h4 style={{ color: 'dimgray' }}>{languages[lang].button_registrationsGenerated}</h4>
                  </GridItem>
                  <GridItem xs={2} sm={2} md={2} lg={2} xl={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <div style={{ marginRight: 5, marginTop: 10 }}>
                      <ExportCSV
                        active={true}
                        csvData={this.state.enrollsTableForExport}
                        fileName='klarway-registry'
                      />
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>


              {/* Card y Search */}
              <CardBody >

                <GridContainer>


                  {/*filtros */}
                  {/* Fecha Desde */}
                  <GridItem xs={12} sm={6} md={6} lg={3} xl={3}>

                    <TextField
                      style={{ width: "100%" }}
                      id="searchStartDate"
                      label={languages[lang].button_from}
                      type="date"
                      variant="outlined"
                      size="small"
                      margin="dense"

                      color="dimgray"
                      value={this.state.searchStartDate}
                      onKeyPress={
                        (ev) => {
                          utilities.elEnter(ev, this.findfunc)
                        }}
                      onChange={(x) => {
                        this.setState({ searchStartDate: x.target.value })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>

                  {/* Fecha Hasta */}
                  <GridItem xs={12} sm={6} md={6} lg={3} xl={3}>
                    <TextField
                      style={{ width: "100%" }}
                      id="searchToDate"
                      label={languages[lang].button_to}
                      type="date"
                      variant="outlined"
                      size="small"
                      margin="dense"

                      color="dimgray"
                      value={this.state.searchToDate}
                      onKeyPress={
                        (ev) => {
                          utilities.elEnter(ev, this.findfunc)
                        }}
                      onChange={(x) => {
                        this.setState({ searchToDate: x.target.value })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={6} sm={6} md={9} lg={3} style={{ display: "flex", alignItems: "center" }}
                    onClick={() => {
                      this.setState({ showFilters: !this.state.showFilters })
                    }}  >
                    <div style={{ paddingLeft: 5 }}>
                      <h4 style={{ fontSize: 14, }}>{languages[lang].button_more}
                      </h4>
                    </div>
                    {/*botón colapsable*/}
                    <IconButton
                      id="unique"
                      style={{ outline: "none" }}

                      aria-expanded={this.state.showFilters}
                      aria-label="Expandir"
                    >
                      {this.state.showFilters ? <ExpandLess /> : < ExpandMoreIcon />}
                    </IconButton>
                  </GridItem>

                  {/* Boton Buscar */}
                  <GridEmptySpace
                    xs="hidden"
                    sm="hidden"
                    md="hidden"
                    lg={1}
                    xl={1}
                  />
                  <GridItem xs={6} sm={6} md={3} lg={2} xl={2} style={{ display: "flex", justifyContent: "flex-end" }}>

                    <div style={{ maxWidth: 150, width: "100%", display: "flex", justifyContent: "flex-end" }}>
                      <BivButtonFind
                        onClick={() => {
                          this.setState({ pageNumber: 1, loading: true }, this.newLoadData)
                        }}
                      />
                    </div>

                  </GridItem>

                </GridContainer>

                <Collapse in={this.state.showFilters} timeout="auto" unmountOnExit>

                  {/* Search */}
                  <CardContent style={{ padding: 0 }}>

                    <GridContainer>

                      {/* Texto Filtro 
                        <GridItem xs={12} sm={6} md={3} lg={2} xl={2} style={{ display: "flex", alignItems: "center" }}>
                          <h1 style={{ fontSize: 16, margin: 0 }}>Filtro por fechas:</h1>
                        </GridItem>*/}



                      <GridContainer style={{ paddingLeft: 16, paddingRight: 16 }}>
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 5, marginBottom: 5 }}>
                          <div style={{ width: "100%", height: 5, borderTop: '1px solid #ced4db' }} />
                        </GridItem>
                      </GridContainer>
                      {/* <GridItem xs={6} sm={9} md={9} lg={3} xl={11}>
<TextField
  style={{ width: "100%", }}
  id="searchFilter"
  label="Nombre/Apellido"
  variant="outlined"
  size="small"
  display="flex"
  margin="dense"
  color="secondary"
  value={this.state.filterText}
  onKeyPress={
    (ev) => {
      utilities.elEnter(ev,this.findfunc)
    }}
  onChange={(x) => {
    let value = (x.target.value != null) ? x.target.value : ''
    this.setState({ filterText: value })
  }}
/>

</GridItem>

<GridItem xs={6} sm={9} md={9} lg={3} xl={11}>

<TextField
  style={{ width: "100%", }}
  id="dni"
  label="DNI"
  variant="outlined"
  size="small"
  display="flex"
  margin="dense"
  color="secondary"
  onKeyPress={
    (ev) => {
      utilities.elEnter(ev,this.findfunc)
    }}
  value={this.state.filterDni}
  onChange={(x) => {
    let value = (x.target.value != null) ? x.target.value : ''
    this.setState({ filterdni: value })
  }}
/>

</GridItem>

<GridItem xs={6} sm={9} md={9} lg={3} xl={11}>

<TextField
  style={{ width: "100%", }}
  id="Usuario"
  label="Usuario"
  variant="outlined"
  size="small"
  display="flex"
  margin="dense"
  color="secondary"
  value={this.state.filterUser}
  onKeyPress={
    (ev) => {
      utilities.elEnter(ev,this.findfunc)
    }}
  onChange={(x) => {
    let value = (x.target.value != null) ? x.target.value : ''
    this.setState({ filterUser: value })
  }}
/>

</GridItem> */}



                      {/* App */}
                      <GridItem xs={12} sm={12} md={6} lg={3} xl={3}>

                        <form noValidate autoComplete="off">
                          <TextField
                            style={{ width: "100%" }}
                            id="searchResultOrigin"
                            select
                            variant="outlined"
                            label={languages[lang].button_source}
                            size="small"
                            margin="dense"
                            color="secondary"
                            value={this.state.searchResultOrigin}
                            onChange={(event) => {
                              this.setState({ searchResultOrigin: event.target.value });
                            }}
                          >
                            {typesOfOrigin.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </form>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6} lg={3} xl={3}>

                        <form noValidate autoComplete="off">
                          <TextField
                            style={{ width: "100%" }}
                            id="searchResultState"
                            select
                            disabled={(this.state.searchResultOrigin === "Registros automáticos") ? true : false}
                            variant="outlined"
                            label={languages[lang].button_state}
                            size="small"
                            margin="dense"
                            color="secondary"
                            value={this.state.searchResultState}
                            onChange={(event) => {
                              this.setState({ searchResultState: event.target.value });
                            }}
                          >
                            {typesOfState.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </form>
                      </GridItem>

                      {/* <GridItem xs={6} sm={9} md={9} lg={4} xl={4}>

<TextField
  style={{ width: "100%", }}
  id="Status"
  label="Estado"
  variant="outlined"
  size="small"
  display="flex"
  margin="dense"
  color="secondary"
  value={this.state.Status}
  onKeyPress={
    (ev) => {
      utilities.elEnter(ev,this.findfunc)
    }}
  onChange={(x) => {
    let value = (x.target.value != null) ? x.target.value : ''
    this.setState({ Status: value })
  }}
/>

</GridItem> */}


                      {/* Filtro Particular */}
                      <GridItem xs={12} sm={6} md={8} lg={3} xl={4}>
                        <TextField
                          style={{ width: "100%", }}
                          id="searchFilter"
                          label={languages[lang].button_keyword}
                          variant="outlined"
                          size="small"
                          margin="dense"
                          color="secondary"
                          value={this.state.searchFilter}
                          onKeyPress={
                            (ev) => {
                              utilities.elEnter(ev, this.findfunc)
                            }}
                          onChange={(x) => {
                            let value = (x.target.value != null) ? x.target.value : ''
                            this.setState({ searchFilter: value, filterText: value })
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={6} md={4} lg={3} xl={2} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

                        <Button
                          round
                          disabled={(!this.state.searchFilter || this.state.searchFilter === "") && (!this.state.searchResultState || this.state.searchResultState === "Todos") && (!this.state.searchResultOrigin || this.state.searchResultOrigin === "Todos")}
                          endIcon={<ClearIcon />}
                          style={{ width: '100%', maxWidth: 150 }}
                          color="#B5C3AE"
                          onClick={() => {
                            this.reset()

                          }}
                        >{languages[lang].button_clean}</Button>

                      </GridItem>


                    </GridContainer>

                  </CardContent>

                </Collapse>

              </CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginLeft: 20, marginRight: 20, marginTop: 11, marginBottom: 11 }}>
                  <div style={{ width: "100%", height: 5, borderTop: '1px solid #ced4db' }} />
                </GridItem>
              </GridContainer>

              <BivResultCount resultArray={this.state.enrollsTable} />

              {/* Table */}
              {this.state.enrollsTable.length > 0 ?

                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    sizePerPage: 20,
                    pageStartIndex: 1,
                    totalSize: this.state.enrollsTable.length
                  })}
                >
                  {
                    ({
                      paginationProps,
                      paginationTableProps
                    }) => (
                      <div>
                        {/* Datos de Tabla , overflow: "scroll" */}
                        <CardBody style={{ maxHeight: 600, overflow: "auto" }}>
                          <GridContainer justify="space-between">
                            <GridItem xs={12} sm={12} md={12} style={{ minWidth: 1050 }}>
                              <BootstrapTable
                                bootstrap4
                                keyField="key"
                                data={this.state.enrollsTable}
                                columns={tableHeader}
                                striped
                                bordered={true}
                                wrapperClasses="table-responsive"
                                classes='scrollable-table'
                                {...paginationTableProps}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>

                        <BivPagination
                          onClickPrev={() => {
                            if (paginationProps.page !== 1) {
                              paginationProps.onPageChange(paginationProps.page - 1)
                            }
                          }}
                          onClickPage={(value) => {
                            paginationProps.onPageChange(value)
                          }}
                          onClickNext={() => {
                            let totalPages = Math.ceil(this.state.enrollsTable.length / 20)
                            if (paginationProps.page !== totalPages) {
                              paginationProps.onPageChange(paginationProps.page + 1)
                            }
                          }}
                          page={paginationProps.page}
                          totalRegistry={this.state.enrollsTable.length}
                        />

                      </div>
                    )
                  }


                </PaginationProvider>

                :

                <GridContainer>
                  <GridItem xs={12} style={{
                    height: 250,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    flexDirection: "column",
                  }}>

                    <Problem
                      style={{ fontSize: 50, marginBottom: 10 }}
                    />
                    <div style={{ fontSize: 19 }}>
                      {languages[lang].button_noRegistrationRequestsWereFound}
                    </div>
                  </GridItem>
                </GridContainer>

              }

            </Card>
          </GridItem>
        </GridContainer>

        <Dialog
          open={this.state.showEnrollDetail}
          fullWidth={true}
          maxWidth={'md'}
          aria-labelledby="dialogTitle"
          aria-describedby="dialogDescription"
          onClose={() => {
            this.setState({ showEnrollDetail: false })
          }}
        >
          <DialogTitle
            id="dialogTitle"
            disableTypography
          >
            <Button
              justIcon
              style={{ position: 'absolute', right: 15 }}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                this.setState({ showEnrollDetail: false })
              }}
            >
              <Close />
            </Button>
            <h4 >{languages[lang].button_applicationInformation}</h4>
          </DialogTitle>

          <DialogContent id="dialogDescription">

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 0, marginBottom: 15 }}>
                {(this.state.enrollSelected.tipo === 'Manual') ?
                  <p style={{ color: "#0bb4e4", fontSize: 12, fontWeight: 'bold' }}>{languages[lang].button_manualRegistration}</p>
                  :
                  <p style={{ color: "#0bb4e4", fontSize: 12, fontWeight: 'bold' }}>{languages[lang].button_automaticRegistration}</p>
                }
              </GridItem>
            </GridContainer>

            {(this.state.enrollSelected.tipo === 'Manual') ?
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: -20, marginBottom: 0 }}>
                  <Card style={{ marginTop: 0 }}>
                    <h4 style={{ paddingLeft: "20px", color: 'black' }}>{languages[lang].button_studentComment}<span style={{ color: 'dimgray' }}>{this.state.enrollSelected.motivo} </span> </h4>
                  </Card>
                </GridItem>
              </GridContainer>
              :
              <div />
            }

            <GridContainer>

              {/* Foto de Usuario */}
              <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ marginBottom: 0, marginTop: -20, height: "400px" }}>

                <Card style={{ marginTop: 0 }}>

                  <CardHeader style={{ marginBottom: 0 }}>
                    <h4 style={{ color: 'dimgray' }}>{languages[lang].button_userPhoto}</h4>
                  </CardHeader>

                  <CardBody style={{ margin: 0 }}>
                    {(this.state.enrollSelected.userimg === '') ?
                      <div style={{ height: '300px', display: "flex", flexDirection: "column", textAlign: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 16, fontWeight: 400, color: '#8c8a88' }}>No tiene</div>
                      :
                      <Image style={{ maxHeight: '300px' }} src={this.state.enrollSelected.userimg} rounded fluid
                        onClick={() => {
                          this.setState({ photoSelected: 0, photoDialog: true })
                        }}
                      />
                    }
                  </CardBody>

                </Card>

              </GridItem>

              {/* Foto de Dni */}
              <GridItem xs={12} sm={6} md={3} lg={3} xl={3} style={{ marginBottom: 0, marginTop: -20, height: "400px" }}>

                <Card style={{ marginTop: 0 }}>

                  <CardHeader>
                    <h4 style={{ color: 'dimgray' }}>Foto de {global.dni_name}</h4>
                  </CardHeader>

                  <CardBody style={{ margin: 0 }}>
                    {(this.state.frenteDni) ?
                      (this.state.enrollSelected.dniimg1 === '') ?
                        <div style={{ height: '260px', display: "flex", flexDirection: "column", textAlign: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 16, fontWeight: 400, color: '#8c8a88' }}>No tiene</div>
                        : //max-height: 50vw;
                        <Image style={{ maxHeight: '260px', display: "flex" }} src={this.state.enrollSelected.dniimg1} rounded fluid
                          onClick={() => {
                            this.setState({ photoSelected: 1, photoDialog: true })
                          }} />
                      :
                      (this.state.enrollSelected.dniimg2 === '') ?
                        <div style={{ height: '260px', display: "flex", flexDirection: "column", textAlign: 'center', justifyContent: 'center', backgroundColor: '#f7f7f7', fontSize: 16, fontWeight: 400, color: '#8c8a88' }}>No tiene</div>
                        :
                        <Image style={{ maxHeight: '260px', display: "flex" }} src={this.state.enrollSelected.dniimg2} rounded fluid
                          onClick={() => {
                            this.setState({ photoSelected: 2, photoDialog: true })
                          }} />
                    }
                    {(this.state.frenteDni) ?
                      null
                      :
                      null
                    }
                  </CardBody>

                </Card>

              </GridItem>

              {/* Datos */}
              <GridItem xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginBottom: 0, marginTop: -20, }}>
                <Card style={{ marginTop: 0, }}>
                  <CardHeader>
                    <h4 style={{ color: 'dimgray' }}>{languages[lang].button_userData}</h4>
                  </CardHeader>
                  <CardBody>

                    {(global.readOnlyUser) ?
                      <div
                        style={{
                          height: 250,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          flexDirection: "column",
                        }
                        }>
                        <Problem
                          style={{ fontSize: 50, marginBottom: 10 }}
                        />
                        <div style={{ fontSize: 19 }}>
                          {languages[lang].button_youAreNotAuthorizedToRegister}
                        </div>
                      </div>
                      :
                      <div>
                        {/* Id Usuario */}
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

                          <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].button_userID}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              {this.state.enrollSelected.user}
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].firstTimeUser}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              {this.state.enrollSelected._firstTimeUser}
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].lastChangeUser}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              {this.state.enrollSelected._lastChangeUser}
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].lastChangeUni}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              {this.state.enrollSelected._lastChangeUni}
                            </GridItem>
                            {/* <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>ID:</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              {this.state.enrollSelected.dni}
                            </GridItem> */}
                          </GridContainer>

                        </GridItem>

                        {/* Dni */}
                        {/* <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 0, marginBottom: 0, display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>

                          <GridContainer style={{ marginTop: 0, marginBottom: 0, display: "flex" }} alignItems="center" justify="center">
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].button_id1}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}> */}
                              {/* css modify in: src/assets/css/material-dashboard-pro-react.css */}
                              {/* <TextField
                                error={this.state._completeElCampo.dni}
                                className='textfielddni'
                                style={{ width: "100%" }}
                                id="textDni"
                                //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                disabled={(global.readOnlyUser === true) ? true : false}
                                type="text"
                                label={languages[lang].button_id}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                color="secondary"
                                value={this.state.textDni}
                                onChange={(x) => {
                                  let value = (x.target.value != null) ? x.target.value : ''
                                  this.setState({ textDni: value }, this.checkData)
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem> */}

                        {/* Fecha de Nacimiento */}
                        {/* <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                          <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>Fecha de nacimiento:</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              <TextField
                                //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                disabled={(global.readOnlyUser === true) ? true : false}
                                style={{ width: "100%" }}
                                id="textBirDate"
                                type="date"
                                variant="outlined"
                                size="small"
                                margin="dense"
                                color="secondary"
                                value={this.state.textBirDate}
                                onChange={(x) => {
                                  this.setState({ textBirDate: x.target.value }, this.checkData)
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem> */}

                        {/* Sexo
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                          <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>Sexo:</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              {(this.state.textSex === true) ?
                                <div>
                                  <Button
                                    //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                    disabled={(global.readOnlyUser === true) ? true : false}
                                    color="lightBlueKW"
                                    size="sm"
                                    onClick={() => { this.setState({ textSex: true }, this.checkData) }}
                                  >F</Button>
                                  <Button
                                    //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                    disabled={(global.readOnlyUser === true) ? true : false}
                                    color="lightBlueKW"
                                    size="sm"
                                    simple
                                    onClick={() => { this.setState({ textSex: false }, this.checkData) }}
                                  >M</Button>
                                </div>
                                :
                                <div>
                                  <Button
                                    //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                    disabled={(global.readOnlyUser === true) ? true : false}
                                    color="lightBlueKW"
                                    size="sm"
                                    simple
                                    onClick={() => { this.setState({ textSex: true }, this.checkData) }}
                                  >F</Button>
                                  <Button
                                    //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                    disabled={(global.readOnlyUser === true) ? true : false}
                                    color="lightBlueKW"
                                    size="sm"
                                    onClick={() => { this.setState({ textSex: false }, this.checkData) }}
                                  >M</Button>
                                </div>
                              }
                            </GridItem>
                          </GridContainer>
                        </GridItem> */}

                        {/* Nombre */}
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                          <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].button_name}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              <TextField
                                error={this.state._completeElCampo.name}
                                //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                disabled={(global.readOnlyUser === true) ? true : false}
                                style={{ width: "100%" }}
                                id="textName"
                                label={languages[lang].button_name1}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                color="secondary"
                                value={this.state.textName}
                                onChange={(x) => {
                                  let value = (x.target.value != null) ? x.target.value : ''
                                  this.setState({ textName: value }, this.checkData)
                                }}
                              />
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        {/* Apellido */}
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                          <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].button_lastName}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              <TextField
                                error={this.state._completeElCampo.lastname}
                                //disabled={(this.state.enrollSelected.res !== 'En espera' || global.readOnlyUser === true) ? true : false}
                                disabled={(global.readOnlyUser === true) ? true : false}
                                style={{ width: "100%" }}
                                id="textLastName"
                                label={languages[lang].button_lastName1}
                                variant="outlined"
                                size="small"
                                margin="dense"
                                color="secondary"
                                value={this.state.textLastName}
                                onChange={(x) => {
                                  let value = (x.target.value != null) ? x.target.value : ''
                                  this.setState({ textLastName: value }, this.checkData)
                                }}
                              />
                            </GridItem>

                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

<GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                        <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{global.dni_name}:</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              {this.state.enrollSelected.dni}
                            </GridItem>
                          </GridContainer>

                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", aligntItem: "center", justifyContent: "flex-start" }}>
                          <GridContainer style={{ display: "flex" }} alignItems="center" justify="center">
                            <GridItem xs={4} sm={4} md={4} lg={4} xl={4}>
                              <h6>{languages[lang].button_verifyUser}</h6>
                            </GridItem>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                              <FormControl component="fieldset">
                                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                  <FormControlLabel
                                    checked={+this.state._userVerify === 1}
                                    value="SI"
                                    control={<Radio color="green" />}
                                    label={languages[lang].button_yes}
                                    labelPlacement="SI"
                                    disabled={!this.rol.editEnrolls}
                                    id='si'
                                    onClick={() => {
                                      if (this.rol.editEnrolls) {
                                        this.verUs(1)
                                      }
                                    }}
                                  />

                                  <FormControlLabel
                                    checked={+this.state._userVerify === 2}
                                    value="NO"
                                    disabled={!this.rol.editEnrolls}
                                    control={<Radio color="red" />}
                                    label={languages[lang].button_not}
                                    labelPlacement="NO"
                                    id='no'
                                    onClick={() => {
                                      if (this.rol.editEnrolls) { this.verUs(2) }
                                    }}
                                  />

                                </RadioGroup>
                              </FormControl>
                            </GridItem>

                            {+this.state._userVerify === 2 ?
                              <GridItem xs={12}>
                                <CustomInput
                                  labelText={languages[lang].button_detailOfRejection}
                                  id="dismissDetail"
                                  success
                                  disabled={!this.rol.editEnrolls}
                                  autoFocus={true}
                                  formControlProps={{
                                    fullWidth: true
                                  }}

                                  inputProps={{
                                    defaultValue: this.textDescription,
                                    //value:this.state.textDescription,
                                    // onKeyPress: (e) => this._handleKeyPress(e),
                                    onKeyDown: (e) => this.handleKeyDown(e),
                                    onChange: (e) => this.handleOnChange(e),
                                  }}
                                />
                              </GridItem>
                              : null}


                          </GridContainer>
                        </GridItem>
                      </div>
                    }
                    {(global.readOnlyUser) ?
                      <div />
                      :

                      <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: "100%", display: "flex", alignItem: "flex-end", justifyContent: "flex-end" }} >
                        <Button
                          style={{ marginRight: '10px' }}
                          color="danger"
                          size="sm"
                          disabled={!this.state._buttonDismiss || !this.rol.editEnrolls}
                          onClick={() => {
                            this.setState({
                              _dismissDialog: true,
                              _buttonDismiss: false,
                              //_buttonAccept: false,
                            })
                          }}
                        >{languages[lang].button_cancel}</Button>
                        <Button
                          color="success"
                          size="sm"
                          disabled={!this.state._buttonAccept || !this.rol.editEnrolls }
                          onClick={() => {
                            if (this.state._buttonAccept) {
                              this.setState({ loading: true }, this.acceptEnroll)
                            }
                          }
                          }
                        >{languages[lang].button_save}</Button>
                      </GridItem>

                    }
                  </CardBody>

                </Card>

              </GridItem>

              {/* Botones o Descripción
              {(this.state.enrollSelected.res !== 'En espera') ?

                <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 0, width: "100%" }} >
                  <Card>
                    <CardBody>
                      <GridContainer>
                        <GridItem xs={9} sm={9} md={4} lg={4} xl={4} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
                          <div style={{ color: "black" }}>Estado de solicitud de empadronamiento:</div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={2} lg={2} xl={2} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
                          <div style={{ color: "grey" }}>{(this.state.enrollSelected.res === 'Aprobada') ? 'Solicitud aceptada' : 'Solicitud rechazada'}</div>
                        </GridItem>

                        <GridItem xs={2} sm={2} md={1} lg={1} xl={1} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
                          <div style={{ color: "black" }}>Detalle:</div>
                        </GridItem>
                        <GridItem xs={10} sm={10} md={5} lg={5} xl={5} style={{ display: "flex", alignItem: "center", justifyContent: "flex-start" }}>
                          <div style={{ color: "grey" }}>{(this.state.enrollSelected.res === 'Aprobada') ? '-' : (this.state.enrollSelected.det === '') ? 'No contiene Detalle' : this.state.enrollSelected.det}</div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </Card>
                </GridItem> */}
              :
              <div />





            </GridContainer>

          </DialogContent>

        </Dialog>




        <Dialog open={this.state._dismissDialog}>

          <DialogContent>

            <GridContainer style={{ width: '400px' }}>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h4 >{languages[lang].button_thisUserHasNotBeenVerifiedYetDoYouWantToLeaveThisSectionWithoutChangingThisStatus}</h4>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: 'space-around', alignItems: "center", marginBottom: 20 }}>
                <Button
                  color="success"
                  onClick={() =>
                    this.cancelDismissEnroll()
                  }>{languages[lang].button_yes}</Button>

                <Button
                  color="danger"
                  //disabled={!this.state._dismissAceptDialog}
                  onClick={() =>
                    this.setState({ _dismissDialog: false, _buttonDismiss: true })}
                >{languages[lang].button_not}</Button>

              </GridItem>

            </GridContainer>

          </DialogContent>

        </Dialog>



        {/* <Dialog
          open={this.state.dismissDialog}
        >
          <DialogContent>

            <GridContainer style={{ width: '400px' }}>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 10 }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <h4 >Detalle de solicitud rechazada:</h4>
                </div>
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <CustomInput
                  labelText="Motivo del rechazo..."
                  id="dismissDetail"
                  success
                  autoFocus={true}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onKeyPress: (e) => this.handleKeyPress(e),
                    onKeyDown: (e) => this.handleKeyDown(e),
                    onChange: (e) => this.handleOnChange(e),
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: 'space-around', alignItems: "center", marginBottom: 20 }}>

                <Button
                  color="danger"
                  onClick={() =>
                    this.cancelDismissEnroll()
                  }
                >
                  CANCELAR
                    </Button>

                <Button color="success"
                  disabled={!this.state.dismissAcceptDialog}
                  onClick={() => {
                    this.setState({ loading: true }, this.acceptDismissEnroll)
                  }}
                >
                  CONFIRMAR
                    </Button>

              </GridItem>

            </GridContainer>

          </DialogContent>
        </Dialog> */}

        <Dialog
          open={this.state.photoDialog}
          fullWidth={true}
          maxWidth={'md'}
          aria-labelledby="photoDialogTitle"
          aria-describedby="photoDialogDescription"
          onClose={() => {
            this.setState({ photoDialog: false })
          }}
        >
          <DialogTitle
            id="photoDialogTitle"
            disableTypography
          >
            <Button
              justIcon
              style={{ position: 'absolute', right: 15 }}
              key="close"
              aria-label="Close"
              color="transparent"
              onClick={() => {
                this.setState({ photoDialog: false })
              }}
            >
              <Close />
            </Button>
            <h4>{languages[lang].button_enlargedImage}</h4>
          </DialogTitle>

          <DialogContent id="photoDialogDescription" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {(this.state.photoSelected === 0) ?
              <Image src={this.state.enrollSelected.userimg} rounded fluid />
              : (this.state.photoSelected === 1) ?
                <Image src={this.state.enrollSelected.dniimg1} rounded fluid />
                :
                <Image src={this.state.enrollSelected.dniimg2} rounded fluid />
            }
          </DialogContent>

        </Dialog>

      </div >
    )
  }
}

export default withStyles(sweetAlertStyle)(BivEnroll)
