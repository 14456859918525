import React from 'react'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';

import cellEditFactory from 'react-bootstrap-table2-editor';

import Button from "components/CustomButtons/Button.jsx";
import Delete from "@material-ui/icons/Delete";
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import Add from "@material-ui/icons/Add";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ArrowDropUp from '@material-ui/icons/ArrowDropUp';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import TextField from '@material-ui/core/TextField';


import GridEmptySpace from 'components/GridEmptySpace/GridEmptySpace'
import { useState } from 'react';


const languages = require('../../assets/translate/translate_proctoringControl.json')
let lang  =  localStorage.lang?localStorage.lang:'es'


function VirtualCamerasList(props) {

  const {
    list,
    spc,
    changeSpc,
    filterText,
    onFilter,
    onDelete,
    onChange,
    onAdd,
    
    // method,

    newItemDialog,
    togleNewItemDialog,

  } = props

  const _processListTemp = list.map((process, i) => { return { key: i, processname: process.pname } }

  )

  const processList = _processListTemp.filter(item =>
    item.processname.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "").includes(filterText.toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, ""))).map(
      filtered => { return { key: filtered.key, processname: filtered.processname } }
    )

  const formatWithIcon = (cell, row) => {
    return (
      <Delete color="action" onClick={() => {
        if (!global.readOnlyUser) {
          onDelete(cell)
        }
      }} />
    )
  }

  const headers = [
    { dataField: "key", text: "Key", hidden: true },
    {
      dataField: "processname",
      text: languages[lang].proctoringC_cameraName,
      sort: true,
      sortCaret: (order, column) => {
        if (!order) return (<span style={{ color: "gray" }}><ArrowDropDown /></span>);
        else if (order === 'asc') return (<span style={{ color: "gray" }}><ArrowDropDown /></span>)
        else if (order === 'desc') return (<span style={{ color: "gray" }}><ArrowDropUp /></span>)
        return null;
      },
      editable: true,
      headerStyle: { width: '360px' },
    },
    {
      dataField: "key",
      text: "",
      formatter: formatWithIcon,
      headerStyle: { width: '30px' },
    },
  ]

  const afterSaveCell = (oldValue, newValue, row, column) => {
    onChange({ row })
  }

  const handleChange = (e) => {
    //const {name, value} = e.currentTarget;
    const { value } = e.currentTarget;
    onFilter(value)
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      addNewProcess()
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      togleNewItemDialog()
    }
  }

  const addNewProcess = () => {
    if (document.getElementById('processNewItem').value !== '') {
      onAdd(document.getElementById("processNewItem").value)
      document.getElementById("processNewItem").value = ''
    }
    togleNewItemDialog()
  }
  const [activeMethod,setActiveMethod] = useState(spc)
  const  controlMethod = (x) => {
    setActiveMethod(x)
    changeSpc(x)
    // if (document.getElementById('spc').value !== false) {
    //   method(document.getElementById("processNewItem").value)
    //   document.getElementById("processNewItem").value = false
    // }
  }


  return (
    <div>

      <Dialog
        open={newItemDialog}
        //onClose={() => this._handleClose("info")}
        aria-labelledby="info-slide-title"
        aria-describedby="info-slide-description"

      >

        <DialogContent>

          <GridContainer style={{ width: '400px' }}>

            <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginTop: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h4 >{languages[lang].proctoringC_enterVCName}</h4>
              </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <CustomInput
                labelText={languages[lang].proctoringC_newCamera}
                id="processNewItem"
                success
                autoFocus={true}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onKeyPress: (e) => handleKeyPress(e),
                  onKeyDown: (e) => handleKeyDown(e),
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: "flex", justifyContent: 'space-around', alignItems: "center", marginBottom: 20 }}>

              <Button
                color="danger"
                onClick={() =>
                  togleNewItemDialog()
                }
              >
                {languages[lang].proctoringC_cancel2}
              </Button>

              <Button color="success"
                onClick={() => {
                  addNewProcess()
                }}
              >
                {languages[lang].proctoringC_add}
              </Button>

            </GridItem>

          </GridContainer>

        </DialogContent>

      </Dialog>

      <GridContainer>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

          <GridItem xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: "column" }}>
           {props.from===1?
            <GridContainer>
              <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
                <div style={{ justifyContent: 'left', alignItems: 'left' }}>
                  <h5> {languages[lang].proctoringC_modality} </h5>
                </div>
                </GridItem>
                
                <GridItem xs={6} sm={6} md={1} lg={2} xl={2}>
                  <div style={{ width: '100%', alignItems: 'flex', justifyContent: 'flex' }}>
                    <Button
                      style={{ width: '100%', alignItems: 'center', justifyContent: 'center',minWidth:"fit-content" }}
                      //justIcon
                      //round
                      simple={activeMethod===false?false:true}
                      color="success"
                      size="sm"
                      round
                      onClick={() => {
                        //props.onClick()
                        controlMethod(false)
                      }}
                    >{languages[lang].proctoringC_tolerant}
                </Button>
                  </div>
                </GridItem>
                <GridItem xs={6} sm={6} md={1} lg={2} xl={2}>
                  <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                      style={{ width: '100%', alignItems: 'center', justifyContent: 'center',minWidth:"fit-content"  }}
                      //justIcon
                      //round
                      simple={(activeMethod||(!activeMethod&&activeMethod!==false ))?false:true}
                      color="warning"
                      size="sm"
                      round
                      onClick={() => {
                       // props.onClick()
                        controlMethod(true)
                      }}
                    >{languages[lang].proctoringC_strict}
                </Button>
                  </div>
                </GridItem>
              
            </GridContainer>
            :null}
            <GridContainer>
              <GridItem xs={10} sm={10} md={3} lg={3} xl={3}>
                <div style={{ justifyContent: 'left', alignItems: 'left' }}>
                  <h5> {languages[lang].proctoringC_virtualCamerasManagement}</h5>
                </div>
              </GridItem>

              <GridEmptySpace
                xs="hidden"
                sm="hidden"
                md="hidden"
                lg={3}
                xl={3}
              />

              <GridItem xs={2} sm={2} md={1} lg={1} xl={1}>

                <div style={{ width: '100%', alignItems: 'right', justifyContent: 'right' }}>
                  {(!global.readOnlyUser) ?
                    <Button
                      style={{ width: '100%', alignItems: 'rigth', justifyContent: 'right' }}
                      justIcon
                      round
                      color="greenBiv"
                      onClick={() => {
                        togleNewItemDialog()
                        setTimeout(() => {
                          // document.getElementById("elNumero").focus()
                        }, 0.5)
                      }}
                    >
                      <Add style={{ width: '100%', alignItems: 'rigth', justifyContent: 'right' }} />
                    </Button>
                    :
                    <div />
                  }

                </div>
              </GridItem>
            </GridContainer>

          </GridItem>
        </GridItem>


        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

          <GridItem xs={12} sm={12} md={7} lg={7} xl={7}>
            <TextField
              style={{ width: "100%", backgroundColor: 'white', padding: 'offset' }}
              id="processFilter"
              label={languages[lang].proctoringC_search}
              variant="outlined"
              size="small"
              margin="dense"
              color="primary"
              onChange={(e) => {
                handleChange(e)
              }}
            />
          </GridItem>

        </GridItem>

        <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>

          <GridItem xs={12} sm={12} md={7} lg={7} xl={7}>
            {(!global.readOnlyUser) ?
              <BootstrapTable
                bootstrap4
                style={{ width: "400px" }}
                keyField="key"
                data={processList}
                columns={headers}
                pagination={paginationFactory({
                  hideSizePerPage: true,
                  paginationSize: 6,
                })
                }
                //filter={ filterFactory() }
                cellEdit={cellEditFactory({
                  mode: "click",
                  blurToSave: true,
                  afterSaveCell
                })}
                striped
                bordered={false}
                wrapperClasses="table-responsive"
                classes='scrollable-table'
              />
              :
              <BootstrapTable
                bootstrap4
                style={{ width: "400px" }}
                keyField="key"
                data={processList}
                columns={headers}
                pagination={paginationFactory({
                  hideSizePerPage: true,
                  paginationSize: 6,
                })
                }
                //filter={ filterFactory() }
                striped
                bordered={false}
                wrapperClasses="table-responsive"
                classes='scrollable-table'
              />
            }

          </GridItem>

        </GridItem>

      </GridContainer>

    </div>

  )

}

export default VirtualCamerasList